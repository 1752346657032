<template>
  <main :key="key">
    <div class="container user__container">
      <section class="user-section">
        <div class="user-section__container">
          <div class="user-section__body">
            <!-- Greeting -->
            <section class="user-section user-greeting flow">
              <h1 class="view-user-home__title">{{ $t('user-portal.user_welcome_message', { name: this.userName })}}</h1>
            </section>

            <!-- Greeting -->
            <section
              class="user-section user-greeting"
              v-if="hasLoyalty">
              <card-loyalty/>
            </section>

            <!-- Your account -->
            <section class="user-section user-account">
              <header class="user-section__header">
                <h2 class="user-section__title">{{ $t('user-portal.user_account_title') }}</h2>
              </header>

              <div class="user-section__actions">
                <router-link
                  v-for="(child, i) in children"
                  :key="i"
                  :to="{ name: child.href }"
                  class="user-link"
                  :class="child.classes">
                  <div class="user-link__first">
                    <!-- Icon -->
                    <ui-icon
                      class="user-link__icon"
                      :glyph="child.icon" />

                    <!-- Title -->
                    <span class="user-link__title">{{ child.title }}</span>
                  </div>

                  <div class="user-link__second">
                    <!-- Description -->
                    <span class="user-link__description">{{ child.description }}</span>
                  </div>
                </router-link>
              </div>
            </section>
          </div>  

          <div 
            class="user-section__illustration" 
            v-if="banner">
            <img
              class="user-section__figure"
              :src="banner"
            />
          </div>
          
          <div 
            class="user-section__illustration -placeholder" 
            :style="`--placeholder-image: url(${placeholder})`"
            v-if="!banner">
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import Config from '@/config'
import CardLoyalty from '../components/ui/card-loyalty'

export default {
  name: 'User',

  components: {
    CardLoyalty
  },

  inject: [
    '$challenge',
    '$embed',
    '$loyalty',
    '$referral',
    '$user',
    '$wallet',
    '$localStorage',
  ],

  data() {
    return {
      key: 1,
      loading: false,
    }
  },

  computed: {
    banner() {
      let ret = null
      let banners = this.$basil.get(this.embed, 'banners', [])

      if(banners && banners.length > 0) {
        ret = banners[0]
      }

      return ret
    },

    children() {
      return [
        {
          title: this.$t('user-portal.user_information_title'),
          description: this.$t('user-portal.user_information_description'),
          icon: 'user',
          href: 'sayl-user-portal.user',
          classes: '-informations',
        },
        {
          title: this.$t('user-portal.user_vouchers_title'),
          description: this.$t('user-portal.user_vouchers_description'),
          icon: 'location',
          href: 'sayl-user-portal.vouchers',
          classes: '-vouchers',
        },
        this.hasWallet ? {
          title: this.$t('user-portal.user_wallet_title'),
          description: this.$t('user-portal.user_wallet_description'),
          icon: 'folders',
          href: 'sayl-user-portal.wallet',
          classes: '-wallet',
        } : null,
        // this.hasWallet ? 
        {
          title: this.$t('user-portal.user_poaps_title'),
          description: this.$t('user-portal.user_poaps_description'),
          icon: 'target',
          href: 'sayl-user-portal.poaps',
          classes: '-poaps',
        },
        // : null,
        this.hasLoyalty ? {
          title: this.$t('user-portal.user_balance_title'),
          description: this.$t('user-portal.user_balance_description'),
          icon: 'star',
          href: 'sayl-user-portal.loyalty',
          classes: '-loyalty',
        } : null,
        this.hasChallenges ? {
          title: this.$t('user-portal.user_challenges_title'),
          description: this.$t('user-portal.user_challenges_description'),
          icon: 'flag',
          href: 'sayl-user-portal.challenges',
          classes: '-challenges',
        } : null,
        {
          title: this.$t('user-portal.user_addresses_title'),
          description: this.$t('user-portal.user_addresses_description'),
          icon: 'location',
          href: 'sayl-user-portal.addresses',
          classes: '-addresses',
        },
        {
          title: this.$t('user-portal.user_receipts_title'),
          description: this.$t('user-portal.user_receipts_description'),
          icon: 'location',
          href: 'sayl-user-portal.receipts',
          classes: '-receipts',
        },
        {
          title: this.$t('user-portal.surveys_title'),
          description: this.$t('user-portal.surveys_description'),
          icon: 'location',
          href: 'sayl-user-portal.surveys',
          classes: '-surveys',
        }
      ].filter(s => s != null)
    },

    embed() {
      return this.$basil.get(this.$embed, 'embed')
    },

    hasBalance() {
      return this.$basil.get(this.user, 'hasBalance', false)
    },

    hasChallenges() {
      return this.$basil.get(this.$challenge, 'active', false)
    },

    hasLoyalty() {
      return !this.$basil.isNil(this.$basil.get(this.$loyalty, 'program', null)) && this.hasBalance
    },

    hasWallet() {
      return [
        this.$basil.get(this.user, 'hasWallet', false) === true,
      ].filter(s => s === false).length === 0
    },

    placeholder() {
      return Config.cdn + 'statics/images/logos/resto/logomark.png'
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    userName() {
      let ret = ''
      let firstname = this.$basil.get(this.user, 'firstname', null)
      let lastname = this.$basil.get(this.user, 'lastname', null)

      if(!this.$basil.isNil(firstname)) {
        ret += `${firstname} `
      }

      if(!this.$basil.isNil(lastname)) {
        ret += `${lastname}`
      }

      return ret
    }
  },
}
</script>
